import * as ControllerCitas from 'controllers/paciente/Citas';
import * as ControllerPacientes from 'controllers/Pacientes';

import React, { useLayoutEffect } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MUIDataTable from "mui-datatables";
import Perfil_Paciente from "components/Perfil/Perfil_Paciente";
import Typography from "@material-ui/core/Typography";
import UserHeader from "components/Headers/UserHeader.js";
import componentStyles from "assets/theme/views/admin/profile.js";
import { convertirTimezone } from "functions/fechas";
import { formatoFecha } from "functions/fechas";
import { makeStyles } from "@material-ui/core/styles";
import { motivosCancelaciones } from 'types/cancelaciones';
import { tabla_traduccion } from "traducciones/tabla_traduccion";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(componentStyles);

function PatientAppointments() {
  let { id } = useParams();
  const classes = useStyles();
  const alert = useAlert();
  const [paciente, setPaciente] = React.useState([]);
  const [registros, setRegistros] = React.useState([]);

  const obtenerCitas = async () => {
    const r = await ControllerCitas.peticionGetCitas(id);
    if (!r.resultado) {
      alert.error(r.mensaje);
    }
    const datos = [];
    for (let i = 0; i < r.data.length; i++) {
      const p=r.data[i];
      if (!p) {
        return;
      }
      const cancelacionMotivo = parseInt(p.cancelacion.detalle);
      const idCancelacion = isNaN(cancelacionMotivo) ? 0 : cancelacionMotivo;
      console.log(cancelacionMotivo);
      datos.push({
        cancelacionDetalle: motivosCancelaciones.find(option => option.value === idCancelacion).label,
        ...p});
    }
    setRegistros(datos);
  }

  const obtenerPaciente = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPaciente(id);
      if (r.resultado) {
        setPaciente(r.data);
        obtenerCitas();
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  useLayoutEffect(() => {
    obtenerPaciente();
  }, []);


  return (
    <>
      <UserHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Citas agendadas
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href="/app/pacientes/listado/"
                      >
                        Volver
                      </Button>
                    </Grid>
                  </Grid>
                } classes={{ root: classes.cardHeaderRoot }}

              ></CardHeader>
              <CardContent style={{ backgroundColor: "white" }}>
                <MUIDataTable
                  title={""}
                  data={(!registros) ? [] :
                    registros.map(item => {
                      return [
                        (item.fechaInicio) ? formatoFecha(convertirTimezone(item.fechaInicio, 'GMT-3'), 'DD/MM/YYYY HH:MM') : 'Indefinido',
                        (item.estado) ? item.estado.nombre : 'Indefinido',
                        (item.cancelacion && item.cancelacion.funcionario) ? item.cancelacion.funcionario.nombre + " " + item.cancelacion.funcionario.apellido : '-',
                        (item.cancelacionDetalle) ? item.cancelacionDetalle : '-',
                        (item.medico) ? item.medico.nombre1 + " " + item.medico.apellido1 : 'Indefinido',
                        (item.consultorio) ? item.consultorio.nombre : 'Indefinido',
                      ]
                    })
                  }
                  columns={
                    [{ name: "fechaInicio", label: "Fecha" }, { name: "estado", label: "Estado" },
                    { name: "cancelador", label: "Cancelador" }, { name: "motivo", label: "Motivo" },
                    { name: "medico", label: "Médico" }, { name: "consultorio", label: "Consultorio" }]}
                  options={{
                    filterType: 'dropdown',
                    selectableRowsHideCheckboxes: true,
                    responsive: 'standard',
                    elevation: 0,
                    textLabels: tabla_traduccion
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Perfil_Paciente tipo={'salud'} paciente={paciente} />
        </Grid>
      </Container>
    </>
  );
}

export default PatientAppointments;