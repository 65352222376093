export const motivosCancelaciones = [
    { value: 0, label: 'Sin motivo' },
    { value: 1, label: 'No responde' },
    { value: 2, label: 'Enfermedad' },
    { value: 3, label: 'Dinero' },
    { value: 4, label: 'Trabajo' },
    { value: 5, label: 'Estudio' },
    { value: 6, label: 'Vacaciones' },
    { value: 7, label: 'Transporte' },
    { value: 8, label: 'Accidente' },
    { value: 9, label: 'Otro' },
];