import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { DialogTitle, FormControl, FormGroup, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { Transicion } from 'components/Efectos/Transicion';
import { formatoFecha } from 'functions/fechas'
import { useLayoutEffect } from "react";
import { useAlert } from "react-alert";
import * as ControllerAgenda from 'controllers/Agenda';
import { ReportProblemOutlined } from '@material-ui/icons';
import componentStyles from "assets/theme/views/admin/profile.js";
import { useDispatch } from 'react-redux';
import { convertirTimezone } from 'functions/fechas';
import { motivosCancelaciones } from 'types/cancelaciones';

const useStyles = makeStyles(componentStyles);

export const Cancelacion = ({ visible, cerrar, id }) => {
    const classes = useStyles();
    const alert = useAlert();
    const dispatch = useDispatch();
    const [cita, setCita] = React.useState([]);
    const [motivoCancelacion, setMotivoCancelacion] = React.useState(0);

    const guardarDatos = async e => {
        setMotivoCancelacion({ idMotivo: e.target.value, detalle: "" })
    };

    async function obtenerCita() {
        if (id) {
            dispatch({ "type": "AVISO_CARGANDO", "data": true });
            const r = await ControllerAgenda.peticionGetCita(id);
            if (!r.resultado) {
                alert.error(r.mensaje);
                dispatch({ "type": "AVISO_CARGANDO", "data": false });
                return;
            }
            setCita(r.data);
            dispatch({ "type": "AVISO_CARGANDO", "data": false });
        }
    }

    async function cancelarCita() {
        if (id && motivoCancelacion) {
            const r = await ControllerAgenda.peticionCancelarCita(id, motivoCancelacion);
            if (r.resultado) {
                cerrar();
                alert.success(r.mensaje);
                //Trigger evento de aviso a Calendario para recargar datos
                dispatchEvent(new Event("cambioAgenda"))
            } else {
                alert.error(r.mensaje);
            }
        } else {
            alert.error("Seleccione un motivo de cancelación")
        }
    }

    useLayoutEffect(() => {
        obtenerCita();
    }, [id]);

    return (
        <Dialog
            maxWidth="md"
            open={visible}
            TransitionComponent={Transicion}
            keepMounted
            onClose={cerrar}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="form-dialog-title" >
                <Typography variant="h2">Cancelación de cita</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div className={classes.plLg4}>
                        <Box marginBottom="1.5rem">
                            <Grid Container >
                                <Typography variant="h3">Información:</Typography>
                                <Grid item xs={12} lg={12}>
                                    <Typography variant="h4"><strong>Paciente:</strong>  {cita.paciente ? cita.paciente.nombre1 + ' ' + cita.paciente.apellido1 : 'Desconocido'} </Typography>
                                    <Typography variant="h4"><strong>Cita:</strong> {cita.fechaInicio && cita.fechaFin ? formatoFecha(convertirTimezone(cita.fechaInicio, 'GMT-3'), 'DD/MM/YYYY HH:MM') + ' - ' + formatoFecha(convertirTimezone(cita.fechaFin, 'GMT-3'), "DD/MM/YYYY HH:MM") : 'Desconocido'} </Typography>
                                    <Typography variant="h4"><strong>Doctor:</strong>  {cita.medico ? cita.medico.nombre1 + ' ' + cita.medico.apellido1 : 'Desconocido'} </Typography>
                                    <Typography variant="h4"><strong>Consultorio:</strong>  {cita.consultorio ? cita.consultorio.nombre : 'Desconocido'} </Typography>
                                    <Typography variant="h4"><strong>Nota:</strong>  {cita.detalle ? cita.detalle : 'No se registró ninguna nota'} </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid Container >
                            <Typography variant="h3">Motivo de cancelación:</Typography>
                            <Grid item xs={12} lg={4}>
                                <FormGroup>
                                    <FormControl variant="outlined">
                                        <Select name="idMotivo" key="idMotivo"
                                            onChange={(e) => guardarDatos(e)}>
                                            {motivosCancelaciones.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid Container >
                            <Grid item xs={2} lg={2}>
                                <Typography variant="h4"><ReportProblemOutlined /> Aviso:</Typography>
                            </Grid>
                            <Grid item xs={10} lg={10}>
                                <Typography variant="h4">Tenga en cuenta que la cancelación afecta el historial del paciente,
                                    por lo cual debe hacerla cuando sea solicitado por el mismo.
                                    Para otras circustancias puede eliminarla desde la agenda.</Typography>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContentText>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center" marginTop="1.5rem" marginBottom="1.5rem">
                    <Button color="secondary" variant="contained" onClick={() => cerrar()}>
                        Cerrar
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => cancelarCita()}>
                        Cancelar
                    </Button>
                </Box>

            </DialogContent>
        </Dialog>
    );
}