import * as ControllerPacientes from 'controllers/Pacientes';

import React, { useLayoutEffect } from "react";

import AlertaDialogo from "components/Popups/AlertaDialogo";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Header from "components/Headers/Header.js";
import Historial from "components/Tabla/Historial";
import MenuTabla from "components/Tabla/MenuTabla";
import Pagination from "@material-ui/lab/Pagination";
import SinRegistros from "components/Tabla/SinRegistros";
import TablaCarga from "components/Tabla/Carga";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import componentStyles from "assets/theme/views/admin/tables.js";
import { convertirTimezone } from "functions/fechas";
import { formatoFecha } from 'functions/fechas';
import { makeStyles } from "@material-ui/core/styles";
import { useAlert } from "react-alert";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(componentStyles);

const Pacientes = () => {
  const classes = useStyles();
  const theme = useTheme();
  let { search } = useLocation();
  const alert = useAlert();
  const [pacientes, setPacientes] = React.useState();
  const [pagina, setPagina] = React.useState(1);
  const [cantidadPaginas, setCantidadPaginas] = React.useState(0);
  const [modalEliminar, setModalEliminar] = React.useState(false);
  const [idEliminar, setIdEliminar] = React.useState();
  const permisos = useSelector((state) => state.usuario ? state.usuario.permisos.paciente : []);

  const peticionDelete = async (id) => {
    cerrarModalEliminar();
    if (id) {
      const r = await ControllerPacientes.peticionDeletePaciente(id);
      if (r.resultado) {
        setPacientes(pacientes.filter(i => i.idPaciente !== id));
        alert.success(r.mensaje);
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  const cambioPagina = (a, b) => {
    setPagina(b)
  }

  const eliminarRegistro = (id) => {
    setIdEliminar(id);
    setModalEliminar(true);
  }

  const cerrarModalEliminar = () => {
    setIdEliminar();
    setModalEliminar(false);
  }

  const itemsMenu = [{ nombre: "Ver", "href": "/app/pacientes/paciente/", "permiso": permisos && permisos.obtener },
  { nombre: "Modificar", "href": "/app/pacientes/datos/personales/", "permiso": permisos && permisos.modificar },
  { nombre: "Ficha médica", "href": "/app/pacientes/ficha/", "permiso": permisos && permisos.ficha },
  { nombre: "Odontograma", "href": "/app/pacientes/odontograma/", "permiso": permisos && permisos.odontograma },
  { nombre: "Periodontogramas", "href": "/app/pacientes/periodontogramas/listado/", "permiso": permisos && permisos.periodontograma },
  { nombre: "Documentos", "href": "/app/pacientes/archivo/", "permiso": permisos && permisos.archivo },
  { nombre: "Cuenta corriente", "href": "/app/pacientes/cuenta/", "permiso": permisos && permisos.cuenta },
  { nombre: "Citas", "href": "/app/pacientes/citas/", "permiso": permisos && permisos.citas },
  { nombre: "Eliminar", "funcion": eliminarRegistro, "permiso": permisos && permisos.eliminar }];


  const obtenerPacientes = async (pagina) => {
    const r = await ControllerPacientes.peticionGetPacientes(pagina);
    if (r.resultado) {
      setPacientes(r.data.registros);
      setCantidadPaginas(r.data.paginado.cantidadDePaginas);
    } else {
      alert.error(r.mensaje);
    }
  };

  const obtenerPacientesCriterio = async (busqueda) => {
    const r = await ControllerPacientes.peticionGetPacientesCriterio({ "nombre": busqueda });
    if (r.resultado) {
      setPacientes(r.data);
      setCantidadPaginas(0);
    } else {
      alert.error(r.mensaje);
    }
  };

  useLayoutEffect(() => {
    const param = new URLSearchParams(search).get("busqueda")
    param ? obtenerPacientesCriterio(param) : obtenerPacientes(pagina);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina]);

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>

          <CardHeader
            subheader={
              <Grid
                container
                component={Box}
                alignItems="center"
                justifyContent="space-between"
              >

                <Grid item xs="auto">
                  <Box
                    component={Typography}
                    variant="h3"
                    marginBottom="0!important"
                  >
                    {search ? 'Resultados de búsqueda' : 'Listado'}
                  </Box>
                </Grid>
                {permisos && permisos.crear &&
                  <Grid item xs="auto">
                    <Box
                      justifyContent="flex-end"
                      display="flex"
                      flexWrap="wrap"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        href="/app/pacientes/datos/personales/"
                      >
                        Nuevo
                      </Button>
                    </Box>
                  </Grid>}
              </Grid>
            }
            classes={{ root: classes.cardHeaderRoot }}
          ></CardHeader>

          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}></TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Apellidos
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Nombres
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Documento
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Teléfono
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    E-mail
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Vto. Carnet
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!pacientes? <TablaCarga espacio={7} />:
                 pacientes.length < 1 ? <SinRegistros espacio={7} />:
                 pacientes && pacientes.map(paciente => {
                    return (
                <TableRow>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    align="right">
                    <MenuTabla id={paciente.idPaciente} items={itemsMenu} />
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {`${paciente.apellido1 ?? ""}${paciente.apellido2 ? " " + paciente.apellido2 : ""}`}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {`${paciente.nombre1 ?? ""}${paciente.nombre2 ? " " + paciente.nombre2 : ""}`}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {paciente.documento}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                  {`${paciente.celular} ${paciente.telefono ? ` / ${paciente.telefono}`:''}`}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {paciente.mail}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box
                      component="span"
                      fontSize=".875rem"
                      color={
                        !paciente.vencimientoCarnetSalud ?
                        null
                        : new Date(paciente.vencimientoCarnetSalud) < new Date() ? theme.palette.warning.main : theme.palette.success.main
                      }
                      display="flex"
                      alignItems="center"
                    >
                      {paciente.vencimientoCarnetSalud ? formatoFecha(paciente.vencimientoCarnetSalud, "DD/MM/YYYY") : "N/A"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Historial creacion={{ fecha: convertirTimezone(paciente.fechaRegistro,'GMT-3') }} modificacion={{ fecha: convertirTimezone(paciente.fechaUltimaModificacion,'GMT-3') }} />
                  </TableCell>
                </TableRow>
                )
                  })},
              </TableBody>
            </Box>
          </TableContainer>
          <Box
            classes={{ root: classes.cardActionsRoot }}
            component={CardActions}
            justifyContent="flex-end"
          >
            <Pagination count={cantidadPaginas} onChange={cambioPagina} color="primary" variant="outlined" />
          </Box>
        </Card>

      </Container>

      {modalEliminar && <AlertaDialogo titulo={"Seguro desea eliminar el paciente seleccionado?"} id={idEliminar} funcionConfirmar={peticionDelete} abierto={modalEliminar} funcionCerrar={cerrarModalEliminar} />}
    </>
  );
};

export default Pacientes;
